$(function() {
    console.log('primafila started');

    // $('.btnTooltip').tooltip({});

    $(document).ready(function(){
        $('[data-toggle="tooltip"]').tooltip({
            animation: true,
            delay: {show: 100, hide: 100}
        });
        $('btnTooltip').on('mousedown', function(){
            $('[data-toggle="tooltip"]').tooltip('hide');
        });
        $('[data-toggle="tooltip"]').on('mouseleave', function(){
            $('[data-toggle="tooltip"]').tooltip('hide');
        });
    });
});
